import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import github from '../img/github-icon.svg'
import logo from '../img/logo.svg'
// import Stripe from '../components/nav/Stripe'
import AnimatedNavbar from '../components/nav/stripe/AnimatedNavbar'
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Accordion from './nav/stripe/Navbar/Accordion';

// let toto = function() {
  
// }
// const Navbar = ({
export class Navbar extends React.Component {
// }) => (

  constructor () {
    super()
    this.state = {
      active: false,
    }
    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(){
    const { active } = this.state;
    this.setState({active: !active});
  }

  render(){
    return(
    <StaticQuery
    query={graphql`
      query {
        allWordpressPage(sort: { fields: wordpress_id }, limit: 5) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `}

    render={data => {
      return(
        <nav className="navbar">
          <div className="navbar__inner">
            <div className="logo">
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/Logo.png" alt="Jaide" />   
              </Link>
            </div>
            <ul className="menu">
              <AnimatedNavbar duration="300"/>
            </ul>
            <div className="mobile-nav" onClick={this.handleClick}>
              <div className="hamburger" ></div>
            </div>
            <div className="right-side">
              <Link to="/devenir-benevole" className="btnBd"><span>DEVENIR BéNéVOLE</span></Link>
              {/* <a href="https://www.paypal.me/associationjaide?locale.x=fr_FR" className="btn" ><CreditCardIcon /><span>FAIRE UN DON</span>  </a> */}
              <Link to="/faire-un-don" className="btn"><CreditCardIcon /><span>FAIRE UN DON</span>  </Link>
            </div>
          </div>

          <div className={`banner ${this.state.active ? "active" : ""}`}>
            <Accordion />
          </div>

        </nav>
      )
    }
  }
  />
    )
  }

  
// )

}

export default Navbar
