import React from 'react'
import FavoriteIcon from '@material-ui/icons/Favorite';
import RestoreIcon from '@material-ui/icons/Restore';

import Link from 'gatsby-link';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import EmailIcon from '@material-ui/icons/Email';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
import AccessibilityIcon from '@material-ui/icons/Accessibility';

// const paragraph = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet natus sint provident vel ab reprehenderit cum soluta, suscipit facere nisi sed earum repellendus fuga debitis, nam molestiae minima voluptates possimus.'

const data = [
  {
    title: 'Qui sommes nous ?',
    paragraph: 'info' 
  },
  {
    title: 'Actions',
    paragraph: 'warning'
  },
  {
    title: 'Contact',
    paragraph: 'error'
  }
]

class Accordion extends React.Component {
  render () {
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }
  
  render () {
    let content;
    let {
      props: {
        paragraph,
        title
      },
      state: {
        opened
      }
    } = this

    switch (paragraph) {
      case 'info':
        paragraph = <div className="right-side"> <ul> <li><Link to="/a-propos"><FavoriteIcon /> JAIDE en quelques mots</Link></li><li><Link to="/blog"><RestoreIcon color="red"/> Blog</Link></li></ul></div>
        // return this.props;
        break
      case 'warning':
      paragraph = <div className="right-side"> <ul> <li><Link to="/j-aide-mon-prochain"><AccessibilityIcon /> J'aide mon prochain</Link></li><li><Link to="/j-aide-ma-ville"><LocationCityIcon /> J'aide ma ville</Link></li><li><Link to="/j-aide-a-l-international"><PublicIcon /> J'aide à l'international</Link></li><li><Link to="/permanences-juridiques"><RestoreIcon color="red"/>permanences juridiques</Link></li><li><Link to="/permanences-sociales"><RestoreIcon color="red"/>permanences sociales</Link></li></ul></div>
        // return this.props;
        break
      case 'error':
      paragraph = <div className="right-side"> <ul> <li><Link to="/contact"><EmailIcon /> Contact</Link></li><li><Link to="/boite-a-idee"><WbIncandescentIcon /> Boite à idées</Link></li><li><Link to="/faire-un-don">Faire un don</Link></li><li><Link to="/devenir-benevole">Devenir bénevole</Link></li><li><Link to="/devenir-partenaire">Devenir partenaire</Link></li></ul></div>
        // return this.props;
        break
      default:
        // return null;
    }
    
    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              {/* <p {...{ className: 'accordion-item__paragraph' }}> */}
                {paragraph}
              {/* </p> */}
            </div>
          </div>
      </div>
    )
  }
}

export default Accordion
// ReactDOM.render(<Accordion />, document.getElementById('app'));