import React from "react"
import styled from "styled-components"
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import EmailIcon from '@material-ui/icons/Email';
import ShareIcon from '@material-ui/icons/Share';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {
  Heading,
  HeadingLink,
  LinkList,
  DropdownSection,
  Icon
} from "./Components"

const CompanyDropdownEl = styled.div`
  width: 18.5rem;
  li a, li h3{
    color: #2e1e4c;
    transition: color 0.3s; 
  }
  li:hover a, li:hover h3{
    color:#ec2341; 
  }
  li:last-child{
    padding-bottom:20px;
  }

  .navSocial{ width:100%; padding:0 20px; }
  //.navSocial li {display:inline-table; margin:3px 3px;}
  .navSocial li ul li{width:100%;}
  .navSocial ul li a{display:inline-table; margin:3px 3px;}
`


const CompanyDropdown = () => {
  return (
    <CompanyDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ul>
          <HeadingLink>
            <Link to="/contact">
              <EmailIcon /> Contact
            </Link>
          </HeadingLink>
          <HeadingLink>
          <Link to="/boite-a-idee">
              <WbIncandescentIcon /> Boite à idées
            </Link>
          </HeadingLink>
        </ul>
      </DropdownSection>
      <DropdownSection>
        <div className="navSocial" marginLeft="25px">
          <HeadingLink>
           <Link to="/devenir-partenaire">
            <SupervisorAccountIcon /> Devenir partenaire
            </Link>
          </HeadingLink>
          <HeadingLink>
            <div className="follow" ><ShareIcon /> Suivez-nous</div>
          </HeadingLink>
          <HeadingLink >
            <ul>
              <li>
              <a href="https://www.facebook.com/AssociationJAIDE/">
              <FontAwesomeIcon size="1x" icon={faFacebook} />
              </a>
              </li>
              <li>
              <a href="https://www.instagram.com/p/BoW2DNhHGyq/">
              <FontAwesomeIcon size="1x" icon={faInstagram} />
              </a>
              </li>
              <li>
              <a href="/#">
              <FontAwesomeIcon size="1x" icon={faTwitter} />
              </a>
              </li>
            </ul>
          </HeadingLink>
        </div>
      </DropdownSection>
    </CompanyDropdownEl>
  )
}

export default CompanyDropdown
