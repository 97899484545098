import React from "react"
import styled from "styled-components"
import Link from 'gatsby-link'
import FavoriteIcon from '@material-ui/icons/Favorite';
import RestoreIcon from '@material-ui/icons/Restore';

import {
  Icon,
  DropdownSection,
  Heading,
  HeadingLink,
  LinkList
} from "./Components"

const DevelopersDropdownEl = styled.div`
  width: 25rem; color:#2e1e4c;
  li:last-child{
    padding-bottom:20px;
  }
  h3{
    // color:#2e1e4c;
    transition: color 0.3s;
  }
 
  p{
    color:#2e1e4c; padding-bottom:20px;
  }
  a{
    color:#2e1e4c;
    transition: color 0.3s;
  }
  a:hover,h3:hover{
    color:#ec2341;
  }
`

const Flex = styled.div`
  display: flex;
  > div:first-of-type {
    margin-right: 48px;
  }
  color:#2e1e4c;
`


const DevelopersDropdown = () => {
  return (
    <DevelopersDropdownEl>
      <DropdownSection data-first-dropdown-section>
      <Link to="/a-propos">
        <div>
         <ul>
          <Heading><FavoriteIcon /> JAIDE en quelques mots</Heading>
          <p>J'Aide j'Impacte je Donne de l'Espoir</p>
         </ul>
          {/* <Flex>
            <div>
              <h4>Get Started</h4>
              <LinkList>
                <li>
                  <a href="/">Elements</a>
                </li>
                <li>
                  <a href="/">Checkout</a>
                </li>
                <li>
                  <a href="/">Mobile apps</a>
                </li>
              </LinkList>
            </div>
            <div>
              <h4>Popular Topics</h4>
              <LinkList>
                <li>
                  <a href="/">Apple Pay</a>
                </li>
                <li>
                  <a href="/">Testing</a>
                </li>
                <li>
                  <a href="/">Launch Checklist</a>
                </li>
              </LinkList>
            </div>
          </Flex> */}
        </div>
        </Link>
      </DropdownSection>
      <DropdownSection>
        <ul>
          <HeadingLink>
            <Link to="/blog">
              <RestoreIcon color="red"/> Blog
            </Link>
          </HeadingLink>
        </ul>
      </DropdownSection>
    </DevelopersDropdownEl>
  )
}

export default DevelopersDropdown
