import React from "react"
import styled from "styled-components"
import FavoriteIcon from '@material-ui/icons/Favorite';
import RestoreIcon from '@material-ui/icons/Restore';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PublicIcon from '@material-ui/icons/Public';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { Icon, DropdownSection, Heading, HeadingLink } from "./Components"
import Link from 'gatsby-link'


const ProductsDropdownEl = styled.div`
  width: 30rem;
  margin:0;
  padding:0;
  li:last-child{
    padding-bottom:20px;
  }

  h3{
    // color:#2e1e4c;
    transition: color 0.3s;
  }
 
  p{
    color:#2e1e4c; padding-bottom:20px;
  }
  a{
    color:#2e1e4c;
    transition: color 0.3s;
  }
  a:hover,h3:hover{
    color:#ec2341;
  }
`

const Logo = styled.div`
  width: 38px;
  height: 38px;
  margin-right: 16px;
  border-radius: 100%;
  opacity: 0.6;
  // background-color: ${({ color }) => `var(--${color})`};
  background-color: white;
  padding: 0rem;
  margin: 0rem;
`

const SubProductsList = styled.ul`
  li {
    display: flex;
     margin-bottom: 0rem;
  }
  h3 {
    margin-right: 1rem;
    width: 3.2rem;
    display: block;
    color:red;
  }
  a {
    color: var(--dark-grey);
    // color: white;
  }
  
`

const ProductsSection = styled.ul`

  li {
    display: flex; padding:0; 
  }
  li:last-child{
    padding-bottom:20px;
  }
  li a, li h3{
    transition: color 0.3s; 
  }
  li:hover a, li:hover h3{
    color:#ec2341; 
  }
  p{
    color:#2e1e4c;
  }
  
`

const WorksWithStripe = styled.div`
 border-top: 2px solid #fff;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacer);
  padding-top: var(--spacer);
}
h3 {
  margin-bottom: 0px;
}


`

const ProductsDropdown = () => {
  return (
    <ProductsDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ProductsSection>
          <li>
            <Link to="/j-aide-mon-prochain">
                <Heading ><AccessibilityIcon /> J'aide mon prochain</Heading>
                {/* <p>A complete payments platform</p> */}
            </Link>
          </li>
          <li>
            <Link to="/j-aide-ma-ville">
              <Heading ><LocationCityIcon /> J'aide ma ville</Heading>
              {/* <p>Build and scale your recurring business model</p> */}
            </Link>
          </li>
          <li>
            <Link to="/j-aide-a-l-international">

              <Heading color="teal"><PublicIcon /> J'aide à l'international</Heading>
              {/* <p style={{ marginBottom: 0 }}>
                Everything platforms need to get sellers paid
              </p> */}
            </Link>
          </li>
        </ProductsSection>
      </DropdownSection>
      <DropdownSection>
        {/*<SubProductsList>
          <li>
            <Heading noMarginBottom>Sigma</Heading>
            <div>Your business data at your fingertips.</div>
          </li>
          <li>
            <Heading noMarginBottom>Atlas</Heading>
            <div>The best way to start an internet business.</div>
          </li>
          <li>
            <Heading noMarginBottom>Radar</Heading>
            <div>Fight fraud with machine learning.</div>
          </li>
        </SubProductsList>
        <WorksWithStripe>
          <Heading noMarginBottom>
            <a href="/">
              <Icon /> Works with Stripe
            </a>
          </Heading>
        </WorksWithStripe>*/}
        <ul>
          <HeadingLink>
            <Link to="/permanences-juridiques">
              <RestoreIcon /> Permanences juridiques
            </Link>
          </HeadingLink>
          <HeadingLink>
            <Link to="/permanences-sociales">
              <RestoreIcon/> Permanences sociales
            </Link>
          </HeadingLink>
        </ul>
      </DropdownSection> 
      
    </ProductsDropdownEl>
  )
}

export default ProductsDropdown
